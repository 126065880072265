<template>
  <lab-task> <new-lab-calorimetry-part-two /></lab-task>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import NewLabCalorimetryPartTwo from '@/tasks/components/simulations/NewLabCalorimetryPartTwo.vue';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'NewCalorimetryPartTwo',
  components: {NewLabCalorimetryPartTwo, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
